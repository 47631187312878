'use client';
import * as yup from 'yup';

import routes from '@config/routes';
import instance, { multipartPost } from '@instance';
import { type LoggedInUserProps } from '@interfaces/auth';
import { Gender } from '@interfaces/tpro/flying-auth';
import { USER_ROLE } from '@utils/auth/roles';
import { dateStringToDateFormat, dateToInputDateFormat } from '@utils/dates';
import devConsole from '@utils/developer-console';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  CalendarIcon,
  Input,
  Label,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Typography,
  Upload01,
  typographyVariants,
} from '@v2/ui';
import { cn } from '@v2/utils/cn';
import { themeConfig } from '@v2/utils/tailwind-reference';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { ChangeEventHandler, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import MaskedInput from 'react-text-mask';
import { useMediaQuery } from 'usehooks-ts';
import { ItemWrapper } from '../../../components/ContentWrapper';
import { DEFAULT_PLACEHOLDER } from '../util';

interface InputDateMaskProps {
  className: string;
  mask: string;
  showMaskOnFocus: boolean;
  showMaskOnHover: boolean;
  value: string | number | readonly string[] | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

type UserProps = {
  user: LoggedInUserProps;
};
export function PersonalContent({ user }: UserProps) {
  const [isEditMode, setIsEditMode] = useState(false);
  return (
    <>
      <ItemWrapper
        title="Profile"
        trigger={
          <Button
            variant="link"
            className="p-0"
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {isEditMode ? 'Cancel' : 'Edit'}
          </Button>
        }
      >
        {isEditMode ? (
          <EditMode user={user} onCancelEditMode={() => setIsEditMode(false)} />
        ) : (
          <ViewMode user={user} />
        )}
      </ItemWrapper>
    </>
  );
}

export const genderMap = {
  [Gender.FEMALE]: 'Female',
  [Gender.MALE]: 'Male',
};

function ViewMode({ user }: UserProps) {
  const userLastNameChar =
    user?.lastName && user?.lastName?.length > 0 ? user.lastName[0] : '';
  return (
    <div className="flex w-full flex-col justify-between gap-y-5 md:flex-row">
      <div className="flex flex-row items-center gap-x-3">
        <Avatar className="border border-gray-300">
          <AvatarImage src={user.imageUrl} className="object-cover" />
          <AvatarFallback
            className={typographyVariants({
              size: 'tmd',
              className: 'font-semibold uppercase',
            })}
          >
            {`${user.firstName[0]}${userLastNameChar}`}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col gap-y-1">
          <Typography size="tsm" className="text-gray-500">
            Name
          </Typography>
          <Typography size="tmd" className="font-semibold">
            {user.firstName}&nbsp;{user.lastName}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-y-1">
        <Typography size="tsm" className="text-gray-500">
          Date of birth
        </Typography>
        <Typography size="tmd" className="font-semibold">
          {user.dateOfBirth
            ? format(new Date(user.dateOfBirth), 'MMM dd, yyyy')
            : DEFAULT_PLACEHOLDER}
        </Typography>
      </div>
      <div className="flex flex-col gap-y-1">
        <Typography size="tsm" className="text-gray-500">
          Gender
        </Typography>
        <Typography size="tmd" className="font-semibold">
          {user.gender ? genderMap[user.gender] : DEFAULT_PLACEHOLDER}
        </Typography>
      </div>
    </div>
  );
}

const profileSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  // middleName: yup.string(),

  dateOfBirth: yup
    .string()
    .required('Please input your age.').nullable(),
  gender: yup.string(),
  imageUrl: yup.string(),
});


const adminProfileSchema = yup.object().shape({
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
  // middleName: yup.string(),

  dateOfBirth: yup
    .string().nullable(),
  gender: yup.string(),
  imageUrl: yup.string(),
});



function EditMode({
  user,
  onCancelEditMode,
}: UserProps & {
  onCancelEditMode: () => void;
}) {
  const { data } = useSession()

  console.log(data?.user)

  const isAdmin = data?.user.role === USER_ROLE.superAdmin
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const { update } = useSession();
  const router = useRouter();
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const imageUrl: string = await uploadFile(acceptedFiles);

    if (imageUrl) {
      setFieldValue('imageUrl', imageUrl);
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  const matches = useMediaQuery('(min-width: 768px)');

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldError
  } = useFormik<yup.InferType<typeof profileSchema>>({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      dateOfBirth: user?.dateOfBirth
        ? dateToInputDateFormat(new Date(user?.dateOfBirth))
        : '',
      gender: user.gender,
      imageUrl: user.imageUrl,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: isAdmin ? adminProfileSchema : profileSchema,
    onSubmit: async (val) => {
      try {
        setLoading(true);
        const {
          firstName,
          middleName,
          lastName,
          dateOfBirth,
          gender,
          imageUrl,
        } = val;
        let dateOfBirthShort = '';

        if (dateOfBirth) {
          const dob = moment(new Date(dateOfBirth));

          if (!dob.isValid() || dob.year() > moment().year())
            return setFieldError('dateOfBirth', 'Invalid date.');

          if (moment().diff(dob, 'years') < 18)
            return setFieldError('dateOfBirth', 'Must be 18 or older.');

          dateOfBirthShort = /[a-zA-Z]/.test(dateOfBirth || '')
            ? ''
            : dateStringToDateFormat(dateOfBirth || '');
        }


        const newUserData = {
          id: user.id,
          firstName,
          middleName,
          lastName,
          dateOfBirth: dateOfBirthShort,
          gender,
          imageUrl,
        };

        const body = JSON.stringify(newUserData);
        const response = await instance.server(routes.editUserProfileInfo, {
          method: 'PATCH',
          body,
        });
        if (response.ok) {
          await update({ ...newUserData });
          router.refresh();
          onCancelEditMode();
        } else {
          const { message } = await response.json();
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
  });

  console.log(errors)

  const uploadFile = async (imageList: File[]) => {
    try {
      setImageLoading(true);
      const data = new FormData();
      // @ts-ignore
      data.append('file', imageList[0]);
      const response = await multipartPost(routes.uploadProfileImage, {
        headers: {
          Authorization: `Bearer ${user.token.accessToken}`,
        },
        body: data,
      });
      return response?.url ?? '';
    } catch (e: any) {
      devConsole(e);
      return '';
    } finally {
      setImageLoading(false);
    }
  };

  return (
    <div className="w-full">
      <div>
        <form
          action="post"
          onSubmit={handleSubmit}
          className="flex w-full flex-col gap-y-5"
        >
          <div className="flex flex-row items-center gap-x-2.5">
            <div className="h-12 w-12">
              <div
                {...getRootProps({
                  onClick: (e) => {
                    if (imageLoading) {
                      e.stopPropagation();
                      return;
                    }
                  },
                })}
                className="flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-primary-50"
              >
                <input {...getInputProps()} />
                {imageLoading && (
                  <Loader
                    style={{
                      width: '28px',
                      height: '28px',
                      transform: 'scale(2)',
                    }}
                  />
                )}

                {values.imageUrl && !imageLoading && (
                  <Avatar className="h-full w-full">
                    <AvatarImage
                      src={values.imageUrl}
                      className="object-coverx"
                    />
                  </Avatar>
                )}

                {!values.imageUrl && !imageLoading && (
                  <Upload01 pathProps={{ stroke: '#FA2846' }} />
                )}
              </div>
            </div>

            <div className="flex flex-col gap-y-1">
              <Typography size="tmd" className="font-semibold">
                Upload photo
              </Typography>
              <Typography size="txs" className="font-medium text-gray-500">
                PNG, JPG or JPEG file under 2MB (256x256px is recommended)
              </Typography>
            </div>
          </div>

          <div className="flex w-full flex-col items-start gap-y-4 md:flex-row md:gap-x-4 md:gap-y-0">
            {/* FIRSTR NAME */}
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="firstName">First name</Label>
              <Input
                name="firstName"
                type="text"
                id="firstName"
                value={values.firstName}
                onChange={handleChange}
                autoComplete="given-name"
                required
                onBlur={handleBlur}
                isError={errors.firstName !== undefined && touched.firstName}
              />
            </div>
            {/* MIDDLE NAME */}
            {/* <div className="f grid w-full max-w-sm items-center gap-1.5">
              <Label htmlFor="middleName">Middle name</Label>
              <Input
                type="text"
                id="middleName"
                name="middleName"
                value={values.middleName}
                onChange={handleChange}
                autoComplete="family-name"
                onBlur={handleBlur}
              />
            </div> */}
            {/* LAST NAME */}
            <div className="grid w-full items-center gap-1.5">
              <Label htmlFor="lastName">Last name</Label>
              <Input
                type="text"
                id="lastName"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                autoComplete="family-name"
                required
                onBlur={handleBlur}
                isError={errors.lastName !== undefined && touched.lastName}
                errorText={errors.lastName}
              />
            </div>
          </div>

          <div className="flex w-full flex-row items-start gap-x-4">
            <div className="flex w-full flex-col space-y-2">
              <Label htmlFor="dateOfBirth" isRequired={!isAdmin}>
                Date of birth
              </Label>
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="MM/DD/YYYY"
                onChange={(e) => setFieldValue('dateOfBirth', e.target.value)}
                valude

                value={values.dateOfBirth}
                required={!isAdmin}
                render={(ref, props) => (
                  <Input
                    id='dateOfBirth'
                    name='dateOfBirth'
                    {...props}

                    startIcon={
                      <CalendarIcon
                        size="20"
                        pathProps={{
                          stroke: themeConfig.theme.colors.gray[500],
                        }}
                      />
                    }
                    ref={ref}
                    onBlur={handleBlur}
                    isError={errors.dateOfBirth !== undefined}
                    errorText={errors.dateOfBirth}
                  />
                )}
              />

              {/**/}
            </div>
            <div className="flex w-full flex-col space-y-[19px]">
              <Label htmlFor="gender">Gender</Label>

              <RadioGroup
                id='gender'
                name="gender"
                defaultValue={values.gender}
                aria-label="Select gender"
                className="flex"
                onChange={handleChange}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    className="RadioGroupItem"
                    value={Gender.MALE}
                    id="male"
                  />
                  <label
                    className={cn(
                      typographyVariants({ size: 'tmd' }),
                      'font-medium'
                    )}
                    htmlFor="male"
                  >
                    Male
                  </label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    className="RadioGroupItem"
                    value={Gender.FEMALE}
                    id="female"
                  />
                  <label
                    className={cn(
                      typographyVariants({ size: 'tmd' }),
                      'font-medium'
                    )}
                    htmlFor="female"
                  >
                    Female
                  </label>
                </div>
              </RadioGroup>
            </div>
            {matches && <div className="w-full" />}
          </div>

          <Button
            size="xl"
            isLoading={loading}
            type="submit"
            className="w-[146px]"
          >
            Save changes
          </Button>
        </form>
      </div>
    </div>
  );
}
