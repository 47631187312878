'use client';

import {
  CAD_CURRENCY,
  CURRENCY_COOKIE,
  USD_CURRENCY,
  USD_RATE,
} from '@utils/currency';
import devConsole from '@utils/developer-console';
import { getCookie } from 'cookies-next';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface CurrencyContextProps {
  priceRate: number;
  currency: string;
  setCurrency: (value: string) => void;
}

const defaultCurrencyContext: CurrencyContextProps = {
  priceRate: 1,
  currency: USD_CURRENCY,
  setCurrency: () => {},
};

const CurrencyContext = createContext<CurrencyContextProps>(
  defaultCurrencyContext
);

export const useCurrencyContext = () => useContext(CurrencyContext);

export const CurrencyContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [priceRate, setPriceRate] = useState(USD_RATE);
  const [currency, setCurrency] = useState(
    (getCookie(CURRENCY_COOKIE) as string) || USD_CURRENCY
  );
  const TWO_MINUTES_IN_SECONDS = 120;

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await fetch(
          `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json`,
          {
            next: { revalidate: TWO_MINUTES_IN_SECONDS },
          }
        );
        const data = await res.json();

        setPriceRate(data.usd.cad.toFixed(2));
      } catch (e) {
        devConsole(e);

        setCurrency(USD_CURRENCY);
      }
    }

    if (currency === CAD_CURRENCY) {
      fetchData();
    } else {
      setPriceRate(USD_RATE);
    }
  }, [currency]);

  return (
    <CurrencyContext.Provider
      value={{
        priceRate,
        currency,
        setCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};
